import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import getTheme from './theme';
import { Helmet } from 'react-helmet-async';
import './About.css'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
  theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .001)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({theme}) {
  const currentTheme = getTheme(theme === 'dark' ? 'dark' : 'light');

  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
    <Helmet>
    <meta name="description" content="Want to know more about our tool or have a specific question? Explore our FAQs and let us know if we've answered your question." />
      <title>BookFilter | FAQs</title>
    </Helmet>
    <ThemeProvider theme={currentTheme}>
    <Typography 
        variant="h1"
        style={{ fontSize: '1.5rem', paddingBottom: '8px'}} 
        className="faq-title"
      >
        Frequently Asked Questions
      </Typography>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Where is the data collected from?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We collect the book data from Goodreads and Storygraph at the moment.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Why should I find this tool useful?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Goodreads and Storygraph currently don't allow users to sort and filter books: <br></br>
            <br></br>
            When looking for a new book, readers usually assemble a list of books which fit their general criteria,
            then explore each further to decide on their next read. Compiling such a list can be quite tedious and time-consuming
            and the tool helps by making this first step relatively painless and accessible to anyone.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>How is this different from a recommendation engine?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Recommendation engines are great when they work. However, when they don't it's difficult to figure out why. With the tool,
            you can modify the parameters of the books displayed yourself and filter out the undesirables.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Why can't I find a book?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We currently only display English books which contain at least 1,000 ratings. If you're an indie author whose books fulfil this criteria
            feel free to send us an email with your books <a href="mailto:include@book-filter.com">here</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>How can I support?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can support us by buying your books through our affiliate links and telling others about us!
            Or send us a message <a href="mailto:sayhi@book-filter.com">here</a> :)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography>I've found a bug. How do I report it?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please send the bug description and any associated details to <a href="mailto:support@book-filter.com">support@book-filter.com</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography>I have a question not listed here.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please send any other queries or questions to <a href="mailto:support@book-filter.com">support@book-filter.com</a>. We'll try to get
            back to you as soon as possible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      </ThemeProvider>
    </div>
  );
}