import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const CustomFormControl = styled(FormControl)({
  bottom: "0.5rem"

});

export default function RadioButtonsGroup({ type, setType }) {
  const handleChange = (event) => {
    setType(event.target.value);
  };
  return (
    <CustomFormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={type}
        onChange={handleChange}
      >
        <FormControlLabel value="" control={<Radio />} label="All" />
        <FormControlLabel value="AND book_series <> ''" control={<Radio />} label="Book Series" />
        <FormControlLabel value="AND NOT book_series <> ''" control={<Radio />} label="Standalone" />
      </RadioGroup>
    </CustomFormControl>
  );
}