// Assuming each book card is a component, add useState to manage its flip state
import React, { useState } from 'react';
import './BookCard.css'; // Import the CSS for styling

const BookCard = ({ book, isMobile }) => {
  const [isFlipped, setIsFlipped] = useState(true);

  // Function to toggle the flip state
  const toggleFlip = () => setIsFlipped(!isFlipped);


  return (
    <div className="book-card" onClick={toggleFlip}>
      <div className={`book-card-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className={`${isFlipped ? 'book-front' : ''}`}>
        <img 
          src={book.cover_image_uri} 
          alt={`Cover of ${book.book_title}`} 
          className="full-width-image"
        />
          <p className={`${isFlipped ? 'text' : 'hide'}`}> View book description</p>
        </div>
        <div className="book-back">
          <p>{book.description_storygraph ? book.description_storygraph : "Description was corrupted during the web-scraping process. We'll have it in the next update!"}</p>
          <p>Click the Goodreads icon for a longer description!</p>
        </div>
      </div>
    </div>
  );
};

export default BookCard;
