// BookList.js
import React, { useRef, useCallback, useState} from 'react';
import './BookList.css';
import BookModal from './BookModal';
import RatingBar from './RatingBar'
import promotedBooks from './PromotedBooksData'

const BookList = ({books, setPage, isMobile, theme, isLoading }) => {
  
  const observer = useRef();
  const lastBookElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [setPage]);

  // const getGenres = (genres) => {
  //   // Assuming 'genres' is a comma-separated string; if it's an array, you don't need to split it.
  //   return genres.slice(0, 3); // Get only the first three genres
  // };

const getRatingBadgeClass = (averageRating) => {
  if (averageRating >= 4) return 'rating-4';
  if (averageRating >= 3) return 'rating-3';
  return 'rating-2';
};

function formatYear(num) {
  if (num < 0) {
    return (num*-1) + ' BC';
  } else if (num < 1000) {
    return num + ' AD';
  } else {
    return num.toString();
  }
}

function truncateTitle(title, maxLength = 100) {
  const maxLen = isMobile ? 30 : maxLength; // Set a shorter maxLength for mobile

  if (title.length > maxLen) {
    return title.substring(0, maxLen) + '...';
  }
  return title;
}

const [showModal, setShowModal] = useState(false);
const [selectedBook, setSelectedBook] = useState(null);

const handleShowModal = (book) => {
  setSelectedBook(book);
  setShowModal(true);
};

const bookGenres = isMobile ? 'mobile-bookGenres' : 'desktop-bookGenres';
const bookCard = isMobile ? 'mobile-bookCard' : 'desktop-bookCard';
const amazonLogo = isMobile ? 'mobile-amazon-logo' : 'amazon-logo';

  return (
    <>
    <div className="bookList">
    {!isLoading && promotedBooks.length > 0 && promotedBooks[0].show === 1 && (
        <div 
        key={promotedBooks[0].book_id_title}
        onClick={() => handleShowModal(promotedBooks[0])}
        className="featured-bookCard" // Use a specific className if you want to style the featured book differently
        >
            <div className="featuredLabel">Featured</div> {/* Featured label */}

          <img 
            className="coverImage" 
            src={promotedBooks[0].cover_image_uri} 
            alt={`Cover of ${promotedBooks[0].book_title}`} 
            loading="lazy"
          />
        <div className="bookInfo">
          <h2 className="bookTitle" style={{transition: 'color 0.8s ease'}}>
            {truncateTitle(promotedBooks[0].book_title)}
          </h2>
          <p className="bookAuthor">{promotedBooks[0].author}</p>
          <p className="datePublished">({formatYear(promotedBooks[0].year)})</p>
          <div className={bookGenres}>
            {isMobile
              ? promotedBooks[0].genres.join(", ")
              : promotedBooks[0].genres.map((genre, genreIndex) => (
                  <span key={genreIndex} className="genreTag">{genre.trim()}</span>
                ))
            }
          </div>
        </div>
        <div className="amazon-div">
        <a
        className="amazonLink"
        href={`https://assoc-redirect.amazon.com/g/r/${promotedBooks[0].amazon_link}?tag=bookfilter07-20`}
        target="_blank"
        rel="noreferrer">
        <img src="/amz_dark.webp" alt="Amazon" className={amazonLogo} />
        </a>
        </div>
      </div>
    )}




      {books.map((book, index) => (
                <div
        className="amazonLink"
        >
        <div 
          key={book.book_id_title} 
          className={bookCard}
          onClick={() => handleShowModal(book)}
          ref={index === books.length - 1 ? lastBookElementRef : null}
        >
          <img 
            className="coverImage" 
            src={book.cover_image_uri} 
            alt={`Cover of ${book.book_title}`} 
            loading="lazy"
          />
          <div className="bookInfo">
          <h2 className="bookTitle" style={{transition: 'color 0.8s ease'}}>
            {truncateTitle(book.book_title)}
          </h2>
            <p className="bookAuthor">{book.author}</p>
            <p className="datePublished">({formatYear(book.year)})</p>
            <div className={bookGenres}>
              {  isMobile
    ? book.genres.join(", ") // Joins genres with a comma for mobile view
    : book.genres.map((genre, index) => (
        <span key={index} className="genreTag">{genre.trim()}</span> // Genre tags for desktop view
      ))}
            </div>
          </div>
          <div className="bookRatings">
            <div className={`ratingBadge ${getRatingBadgeClass(book.average_rating_true)}`}>
              {book.average_rating_true}
            </div>
            <RatingBar source="Goodreads" rating={book.average_rating} numRatings={book.num_ratings} leftpadding={5} />
            {
              book.average_rating_storygraph != null && (
                <RatingBar
                  source="StoryGraph"
                  rating={book.average_rating_storygraph}
                  numRatings={book.num_ratings_storygraph}
                  leftpadding={2.5}
                />
              )
            }
          </div>
        </div>
        </div>
      ))}
    </div>
    <BookModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        book={selectedBook}
        isMobile={isMobile}
        theme={theme}
      />
  </>
  );
};

export default BookList;
