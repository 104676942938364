// BookModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BookCard from './BookCard.js'; // Import the CSS for styling
import './BookModal.css'; // Import custom CSS

const BookModal = ({ show, handleClose, book, isMobile, theme }) => {
  if (!book) return null; // Return null if no book is selected

  const getRatingBadgeClass = (averageRating) => {
    if (averageRating >= 4) return 'rating-4-modal';
    if (averageRating >= 3) return 'rating-3-modal';
    return 'rating-2-modal';
  };
  
  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + 'k';
    } else {
      return num.toString();
    }
  }
  
  function formatYear(num) {
    if (num < 0) {
      return (num*-1) + ' BC';
    } else if (num < 1000) {
      return num + ' AD';
    } else {
      return num.toString();
    }
  }

  function formatMonth(num) {
    const months = [
      "January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December"
    ];
  
    // Check if the number is within the valid range of 0-11
    if (num >= 1 && num <= 12) {
      return months[num-1];
    } else {
      return "Invalid month"; // Return a message or handle the error as needed
    }
  }  

  const bookCardLogoDesktop = isMobile ? 'hide' : 'separate-logos-row-desktop'
  const bookCardLogoMobile = isMobile ? 'separate-logos-row-mobile' : 'hide'
  const mobileBottomRightClose = isMobile ? 'mobile-close' : 'hide'
  const redditLogo = theme === "light" ? "/reddit-light.png" : "/reddit-dark.png";
  const amazonLogo = theme === "light" ? "/amazon-light.png" : "/amazon-dark.png";





  const url_goodreads = "https://www.goodreads.com/book/show/" + book.book_id_title;

  return (
    <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg" 
        aria-labelledby="contained-modal-title-vcenter" 
        centered
        className="custom-modal-class" // This applies your custom styles to the modal
        dialogClassName="custom-modal-dialog-class"
        contentClassName="custom-modal-content-class" // Specifically for modal content styles
        closebutton
    >
      <Modal.Body>
        <div className="container">
        <button type="button" className="custom-close" onClick={handleClose} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <button type="button" className={mobileBottomRightClose} onClick={handleClose} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <div className="row">
            <div className={`col-md-4 modal-book-cover ${isMobile ? 'mobile-book-cover' : ''} no-left-padding`}>
                  <BookCard book={book} isMobile={isMobile}/>
                </div>
                <div className="col-md-4 modal-book-data no-left-padding">
                  <div className="separate-logos-col">
                  <div className="bookInfo">
                <h2 className="bookTitle-modal" style={{transition: 'color 0.8s ease'}}>
                  {book.book_title}
                </h2>
                  <p className="bookSeries-modal">{book.book_series}</p>
                  <p className="bookAuthor-modal">{book.author}</p>
                  <p className="datePublished">(Published {formatMonth(book.month)}, {formatYear(book.year)})</p>
                  <p className="datePublished">{book.num_pages} pages</p>
                  <div className="desktop-bookGenres-modal">
                    {book.genres.map((genre, index) => (
              <span key={index} className="genreTag-modal">{genre.trim()}</span> // Genre tags for desktop view
            ))}
                  </div>
                </div>
                <div>
                  <div className={bookCardLogoDesktop}>
                  <div>
                  <div className="push-left">
                  <div className="center-contents">
                    <span className="small-text"> See more discussion on:</span>
                    <a
                    className="amazonLink"
                    href={`https://www.reddit.com/search/?q=${encodeURIComponent(book.book_title + ' ' + book.author)}`}
                    target="_blank"
                    rel="noreferrer">
                    <img src={redditLogo} alt="Reddit" className="logos"/>
                    </a>
                  </div>
                  </div>
                  </div>
                  <div>
                    <div className="push-right">
                    <div className="center-contents">
                    <span className="small-text"> Support by purchasing via:</span>
                    <a
                    className="amazonLink"
                    href={`https://assoc-redirect.amazon.com/g/r/https://www.amazon.com/s?k=${encodeURIComponent(book.book_title + ' ' + book.author)}&tag=bookfilter07-20`}
                    target="_blank"
                    rel="noreferrer">
                    <img src={amazonLogo} alt="Amazon" className="logos"/>
                    </a>
                    </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
            </div>
            <div className="col-md-4 modal-book-rating no-left-padding">
              <div className="bookRatings">
                <div className={`hide-on-mobile ratingBadge-modal ${getRatingBadgeClass(book.average_rating_true)}`}>
                  {book.average_rating_true}
                </div>
              </div>
              <table id="myTable" className="table table-hover table-borderless table-sm">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Avg. Rating</th>
                  <th scope="col">Num. Ratings</th>
                  <th scope="col">Num. Reviews</th>
                </tr>
              </thead>
              <tbody>
                <tr data-url={url_goodreads} style={{ cursor: 'pointer' }}>
                  <th scope="row"><img src="/gr.png" alt="GR" style={{width: '30px', height: '30px'}}></img></th>
                  <td>
                    <div className={`cell-content ${getRatingBadgeClass(book.average_rating)}`}>
                        {book.average_rating}
                      </div>
                  </td>
                  <td>{formatNumber(book.num_ratings)}</td>
                  <td>{formatNumber(book.num_reviews)}</td>
                </tr>
                <tr data-url={book.url_storygraph} style={{ cursor: 'pointer' }}>
                  <th scope="row"><img src="/sg.png" alt="SG" style={{width: '30px', height: '30px', borderRadius: "20%"}}></img></th>
                  <td>
                    <div className={`cell-content ${getRatingBadgeClass(book.average_rating_storygraph)}`}>
                        {book.average_rating_storygraph}
                      </div>
                  </td>
                  <td>{book.num_ratings_storygraph != null ? formatNumber(book.num_ratings_storygraph) : '-'}</td>
                  <td>-</td>
                </tr>
                <tr style={{ cursor: 'default' }}>
                  <th scope="row"></th>
                  <td class="black">
                    <div className={`cell-content ${getRatingBadgeClass(book.average_rating_true)}`}>
                        {book.average_rating_true}
                      </div>
                  </td>
                  <td class="cell-content white">{book.top_num_ratings}</td>
                  <td class="cell-content white">{book.top_num_reviews}</td>
                </tr>
              </tbody>
            </table>
              <div className={bookCardLogoMobile}>
                <div>
                <div className="push-left">
                <div className="center-contents">
                  <span className="small-text"> See more discussion on:</span>
                  <a
                    className="amazonLink"
                    href={`https://www.reddit.com/search/?q=${encodeURIComponent(book.book_title + ' ' + book.author)}`}
                    target="_blank"
                    rel="noreferrer">
                  <img src={redditLogo} alt="Reddit" className="logos"/>
                  </a>
                </div>
                </div>
                </div>
                <div>
                  <div className="push-right">
                  <div className="center-contents">
                  <span className="small-text"> Support by purchasing via:</span>
                  <a
                    className="amazonLink"
                    href={`https://assoc-redirect.amazon.com/g/r/https://www.amazon.com/s?k=${encodeURIComponent(book.book_title + ' ' + book.author)}&tag=bookfilter07-20`}
                    target="_blank"
                    rel="noreferrer">
                  <img src={amazonLogo} alt="Amazon" className="logos"/>
                  </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BookModal;
