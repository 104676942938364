// SimpleFilter.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BookList from './BookList.js';
import './Loader.css';
import ShareButton from './ShareButton.js';
import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import './SimpleFilter.css'
import { Helmet } from 'react-helmet-async';



function SimpleFilter({
  theme,
  isMobile,
  presetMinRating,
  presetMaxRating,
  presetMinRatingNo,
  presetMaxRatingNo,
  presetMinYear,
  presetMaxYear,
  presetSort,
  presetInclude,
  presetExclude,
  presetInitialBooks})

  {
  const [books, setBooks] = useState(presetInitialBooks);
  const [promotedBooks, setPromotedBooks] = useState([]);
  const [searchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [minRating] = useState(presetMinRating);
  const [maxRating] = useState(presetMaxRating);
  const [minRatingNo] = useState(presetMinRatingNo);
  const [maxRatingNo] = useState(presetMaxRatingNo);
  const [minYear] = useState(presetMinYear);
  const [maxYear] = useState(presetMaxYear);
  const [genres] = useState(presetInclude);
  const [genresExclude] = useState(presetExclude);
  const [sort] = React.useState(presetSort);
  const [page, setPage] = useState(1); // Add a new state for current page
  const [hasMore, setHasMore] = useState(true); // State to track if more data is available
  const [triggerFetch] = useState(false);


  const searchTermRef = useRef(searchTerm);
  const minRatingRef = useRef(minRating);
  const maxRatingRef = useRef(maxRating);
  const minRatingNoRef = useRef(minRatingNo);
  const maxRatingNoRef = useRef(maxRatingNo);
  const minYearRef = useRef(minYear);
  const maxYearRef = useRef(maxYear);
  const genresRef = useRef(genres);
  const genresExcludeRef = useRef(genresExclude);
  const sortRef = useRef(sort);

// Update refs whenever minRating or maxRating changes
  useEffect(() => {
  searchTermRef.current = searchTerm;
  minRatingRef.current = minRating;
  maxRatingRef.current = maxRating;
  minRatingNoRef.current = minRatingNo;
  maxRatingNoRef.current = maxRatingNo;

  if (minYear === "All-time") {
      minYearRef.current = -2000;
  } else if (minYear === "2000s") {
    minYearRef.current = 2000
  } else if (minYear === "1900s") {
    minYearRef.current = 1900
  } else if (minYear === "1800s") {
    minYearRef.current = 1800
  } else if (minYear === "AD") {
    minYearRef.current = 0
  } else if (minYear === "BC") {
    minYearRef.current = -200
  } else {
    minYearRef.current = minYear;
  }
  
  
  if (maxYear === "All-time") {
    maxYearRef.current = 2024;
} else if (maxYear === "2000s") {
  maxYearRef.current = 2009
} else if (maxYear === "1900s") {
  maxYearRef.current = 1999
} else if (maxYear === "1800s") {
  maxYearRef.current = 1899
} else if (maxYear === "AD") {
  maxYearRef.current = 1799
} else if (maxYear === "BC") {
  maxYearRef.current = -1
} else {
  maxYearRef.current = maxYear;
  }


  genresRef.current = genres;
  genresExcludeRef.current = genresExclude;
  sortRef.current = sort;

}, [searchTerm, minRating, maxRating, minRatingNo, maxRatingNo, minYear, maxYear, genres, genresExclude, sort]);


  const fetchBooks = useCallback(async (append = false) => {
    setIsLoading(true);
    
    // Convert the array to a JSON string
    const genreString = JSON.stringify(genresRef.current);
    const genreXString = JSON.stringify(genresExcludeRef.current);

    const response = await fetch(`/api/books?searchTerm=${searchTermRef.current}&minRating=${minRatingRef.current}&maxRating=${maxRatingRef.current}&minRatingNo=${minRatingNoRef.current}&maxRatingNo=${maxRatingNoRef.current}&minYear=${minYearRef.current}&maxYear=${maxYearRef.current}&genreString=${genreString}&genreXString=${genreXString}&sort=${sortRef.current}&type=&page=${page}&booksPerPage=10`);
    const data = await response.json();

    // const response_promo = await fetch(`/api/promo`);
    // const promoted_books = await response_promo.json();
    // setPromotedBooks(promoted_books)
    setPromotedBooks([])

    if (append) {
      setBooks(prevBooks => [...prevBooks, ...data]); // Append new books to existing list
    } else {
      setBooks(data);
    }
    setHasMore(data.length > 0); // If no data returned, set hasMore to false
    setIsLoading(false);
  }, [page]);
  
  
  useEffect(() => {
    if (page > 20) {
      setHasMore(false)
    }
    else if (page > 1 && hasMore) {
      fetchBooks(true);
    }
    else if (page === 1) {
      fetchBooks()
    }
  }, [page, fetchBooks, hasMore, triggerFetch]);
  

  const device_type = isMobile ? 'mobile' : 'desktop'
  const device_width = isMobile ? '100%' : '55%'

  return (
    <>
    <Helmet>
      <title>{`BookFilter | The Top 10 ${presetInclude} Books of ${presetMaxYear}`}</title>
    </Helmet>
    <Container fluid className={device_type} style={{ width: device_width, maxWidth: '1000px', margin: '0 auto' }}>
      <h1 className="title">
        Top 10 {presetInclude} Books {presetMaxYear}
      </h1>
      <h2 className="under"> (sorted by popularity) </h2>
    <Row>
        <Col md={12}>
      <div className="input-group mb-3">
      <Row>
        <Col md={12}>
        {isMobile ? <ShareButton /> : null}
          <div class="dropdown show">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {presetMaxYear}
          </a>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" href={`/top-10/All-time/${presetInclude}`}>All-time</a>
            <a class="dropdown-item" href={`/top-10/2024/${presetInclude}/`}>2024</a>
            <a class="dropdown-item" href={`/top-10/2023/${presetInclude}/`}>2023</a>
            <a class="dropdown-item" href={`/top-10/2022/${presetInclude}/`}>2022</a>
            <a class="dropdown-item" href={`/top-10/2021/${presetInclude}/`}>2021</a>
            <a class="dropdown-item" href={`/top-10/2020/${presetInclude}/`}>2020</a>
            <a class="dropdown-item" href={`/top-10/2019/${presetInclude}/`}>2019</a>
            <a class="dropdown-item" href={`/top-10/2018/${presetInclude}/`}>2018</a>
            <a class="dropdown-item" href={`/top-10/2017/${presetInclude}/`}>2017</a>
            <a class="dropdown-item" href={`/top-10/2016/${presetInclude}/`}>2016</a>
            <a class="dropdown-item" href={`/top-10/2015/${presetInclude}/`}>2015</a>
            <a class="dropdown-item" href={`/top-10/2014/${presetInclude}/`}>2014</a>
            <a class="dropdown-item" href={`/top-10/2013/${presetInclude}/`}>2013</a>
            <a class="dropdown-item" href={`/top-10/2012/${presetInclude}/`}>2012</a>
            <a class="dropdown-item" href={`/top-10/2011/${presetInclude}/`}>2011</a>
            <a class="dropdown-item" href={`/top-10/2010/${presetInclude}/`}>2010</a>
            <a class="dropdown-item" href={`/top-10/2000s/${presetInclude}/`}>2000s</a>
            <a class="dropdown-item" href={`/top-10/1900s/${presetInclude}/`}>1900s</a>
            <a class="dropdown-item" href={`/top-10/1800s/${presetInclude}/`}>1800s</a>
            <a class="dropdown-item" href={`/top-10/AD/${presetInclude}/`}>AD</a>
            <a class="dropdown-item" href={`/top-10/BC/${presetInclude}/`}>BC</a>
          </div>
          <div class="dropdown show">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {genres}
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Adult/`}>Adult</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Adventure/`}>Adventure</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Biography/`}>Biography</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Childrens/`}>Childrens</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Christian/`}>Christian</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Classics/`}>Classics</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Comics/`}>Comics</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Contemporary/`}>Contemporary</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Crime/`}>Crime</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Dystopia/`}>Dystopia</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Entrepreneurship/`}>Entrepreneurship</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Fiction/`}>Fiction</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Fantasy/`}>Fantasy</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Historical/`}>Historical</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Horror/`}>Horror</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Humor/`}>Humor</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Mystery/`}>Mystery</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Nonfiction/`}>Nonfiction</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Paranormal/`}>Paranormal</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Poetry/`}>Poetry</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Psychology/`}>Psychology</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Religion/`}>Religion</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Romance/`}>Romance</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Science Fiction/`}>Science Fiction</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Self Help/`}>Self Help</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Thriller/`}>Thriller</a>
          <a class="dropdown-item" href={`/top-10/${presetMaxYear}/Young Adult/`}>Young Adult</a>


          </div>
        </div>
        </div>
      </Col>
      </Row>
      </div>
      </Col>
      </Row>
      <Row>
        <Col md={12}>
        {
          isLoading || books.length > 0
            ? <BookList isLoading={isLoading} books={books} promotedBooks={promotedBooks} setPage={setPage} isMobile={isMobile} theme={theme} />
              : <p className="d-flex justify-content-center align-items-center">No search results found.</p>
        }     
      </Col>
      </Row>
      <Row>
        <Col md={12}>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80px' }}>
          <div className="loader">
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
      )}
            </Col>
      </Row>

      {!isLoading && hasMore && (
        <div
          id="load-more-anchor"
          style={{ height: '20px', margin: '10px 0' }}
        ></div>
      )}
    </Container>
    </>
  );
}

export default SimpleFilter;
