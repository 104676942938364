import './navBar.css';

export default function NavBar () {

    return(
        <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">BookFilter</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
             <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">Home</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/promote">Promote a book</a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/about">FAQs</a>
              </li>
            </ul>
          </div>
        </div>
        </nav>
    );
}