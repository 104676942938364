import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Adult",
  "Adventure",
  "Biography",
  "Childrens",
  "Christian",
  "Classics",
  "Comics",
  "Contemporary",
  "Crime",
  "Dystopia",
  "Entrepreneurship",
  "Fiction",
  "Fantasy",
  "Historical",
  "Horror",
  "Humor",
  "Mystery",
  "Nonfiction",
  "Paranormal",
  "Poetry",
  "Psychology",
  "Religion",
  "Romance",
  "Science Fiction",
  "Self Help",
  "Thriller",
  "Young Adult"
];

function getStyles(name, genres, theme) {
  return {
    fontWeight:
      genres.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({ genres, setGenres, label }) {
  const theme = useTheme();
  const selectRef = useRef(null);
  const [open, setOpen] = useState(false); // State to control dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure the ref exists and the dropdown is open
      if (selectRef.current && open) {
        const dropdownMenu = document.querySelector('.MuiPaper-root');
  
        // Check if the click is outside both the Select component and the dropdown menu
        if (!selectRef.current.contains(event.target) && (!dropdownMenu || !dropdownMenu.contains(event.target))) {
          setOpen(false); // Close the dropdown
        }
      }
    };
  
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
  
    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]); // Dependency on 'open' to ensure it has the latest state
  

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setGenres(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleOpen = () => {
    if (!isHoveringChip) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isHoveringChip, setIsHoveringChip] = useState(false); // State to track hovering over a chip

  const handleDeleteGenre = (genreToRemove) => {

    setIsHoveringChip(false); // Reset hover state when a genre is removed

    setGenres(genres.filter(genre => genre !== genreToRemove));
  };

  return (
    <div>
      <FormControl sx={{ m: 0, minWidth: 300, maxWidth: 'min(50vw, 1000px)' }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          ref={selectRef}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          value={genres}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} //onClick={(event) => handleDeleteGenre(value)}
                onMouseEnter={() => setIsHoveringChip(true)}
                onMouseLeave={() => setIsHoveringChip(false)}
                onDelete={(event) => handleDeleteGenre(value)}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, genres, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}