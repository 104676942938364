const initialBooks = 
[
    {
        "book_id_title": "50196744-know-my-name",
        "book_id": 50196744,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1567612158l/50196744.jpg",
        "book_title": "Know My Name",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 384,
        "author": "Chanel Miller",
        "genres": [
            "Nonfiction",
            "Biography",
            "Adult"
        ],
        "num_ratings": 205242,
        "num_reviews": 28425,
        "average_rating": 4.7,
        "year": 2019,
        "month": 9,
        "url_storygraph": "https://app.thestorygraph.com/books/60e73d4f-3d13-4afd-988a-f4b50ced8a79",
        "average_rating_storygraph": 4.83,
        "num_ratings_storygraph": 37975,
        "average_rating_true": 4.77,
        "top_num_ratings": "Top 10k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "Know My Name by Chanel Miller is a powerful and inspiring memoir that is a must-read for anyone who has ever been affected by sexual assault, is passionate about feminism and social justice, or is looking for a story of resilience and courage in the face of adversity."
    },
    {
        "book_id_title": "17332218-words-of-radiance",
        "book_id": 17332218,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1659905768i/17332218.jpg",
        "book_title": "Words of Radiance",
        "book_series": "The Stormlight Archive #2",
        "book_series_uri": "https://www.goodreads.com/series/49075-the-stormlight-archive",
        "num_pages": 1087,
        "author": "Brandon Sanderson",
        "genres": [
            "Fantasy",
            "Fiction",
            "Adult",
            "Adventure"
        ],
        "num_ratings": 360571,
        "num_reviews": 22454,
        "average_rating": 4.76,
        "year": 2014,
        "month": 3,
        "url_storygraph": "https://app.thestorygraph.com/books/8b279801-c903-4909-900f-ef852c87f65c",
        "average_rating_storygraph": 4.74,
        "num_ratings_storygraph": 31285,
        "average_rating_true": 4.75,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "Words of Radiance by Brandon Sanderson is a perfect fit for readers who crave epic fantasy adventures filled with emotional depth, thrilling action, and complex moral themes, particularly those who appreciate stories that explore the intricacies of honor, war, and the struggle between light and darkness."
    },
    {
        "book_id_title": "20342617-just-mercy",
        "book_id": 20342617,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1635827409i/20342617.jpg",
        "book_title": "Just Mercy",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 336,
        "author": "Bryan Stevenson",
        "genres": [
            "Nonfiction",
            "Biography"
        ],
        "num_ratings": 234605,
        "num_reviews": 25981,
        "average_rating": 4.63,
        "year": 2014,
        "month": 10,
        "url_storygraph": "https://app.thestorygraph.com/books/33858a8e-08dc-4d28-9c68-e35b0642f6d1",
        "average_rating_storygraph": 4.73,
        "num_ratings_storygraph": 19777,
        "average_rating_true": 4.68,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "Just Mercy: A Story of Justice and Redemption by Bryan Stevenson is a powerful and inspiring book that is a good fit for readers who are passionate about social justice, equality, and the pursuit of mercy, and are looking for a stimulating and emotionally resonant exploration of the complexities of the American criminal justice system."
    },
    {
        "book_id_title": "34964905-the-sun-does-shine",
        "book_id": 34964905,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1591203630i/34964905.jpg",
        "book_title": "The Sun Does Shine: How I Found Life and Freedom on Death Row",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 272,
        "author": "Anthony Ray Hinton",
        "genres": [
            "Nonfiction",
            "Biography",
            "Crime"
        ],
        "num_ratings": 61413,
        "num_reviews": 8704,
        "average_rating": 4.65,
        "year": 2018,
        "month": 3,
        "url_storygraph": "https://app.thestorygraph.com/books/cacfb77d-b4a6-4df8-92eb-9172a524cd4b",
        "average_rating_storygraph": 4.71,
        "num_ratings_storygraph": 5629,
        "average_rating_true": 4.68,
        "top_num_ratings": "Top 10k",
        "top_num_reviews": "Top 10k",
        "description_storygraph": "The Sun Does Shine: How I Found Life and Freedom on Death Row by Lara Love Hardin, Anthony Ray Hinton is a powerful and inspiring memoir that is a good fit for readers who appreciate stories of hope, resilience, and the power of the human spirit, particularly those interested in true stories of wrongful imprisonment, social justice, and the impact of the death penalty on individuals and society."
    },
    {
        "book_id_title": "33590260-kingdom-of-ash",
        "book_id": 33590260,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1531922609i/33590260.jpg",
        "book_title": "Kingdom of Ash",
        "book_series": "Throne of Glass #7",
        "book_series_uri": "https://www.goodreads.com/series/51288-throne-of-glass",
        "num_pages": 980,
        "author": "Sarah J. Maas",
        "genres": [
            "Young Adult",
            "Romance",
            "Fiction",
            "Fantasy"
        ],
        "num_ratings": 593374,
        "num_reviews": 61927,
        "average_rating": 4.69,
        "year": 2018,
        "month": 10,
        "url_storygraph": "https://app.thestorygraph.com/books/76fd6d99-9191-4cc9-89d6-27406ccd891d",
        "average_rating_storygraph": 4.64,
        "num_ratings_storygraph": 82803,
        "average_rating_true": 4.66,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "Kingdom of Ash by Sarah J. Maas is a must-read for fans of epic fantasy and romance who crave a heart-pounding, emotional journey filled with found family, loyalty, and the ultimate test of wills, as Aelin Galathynius's thrilling adventure reaches its unforgettable conclusion."
    },
    {
        "book_id_title": "52122096-manacled",
        "book_id": 52122096,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1675561913i/52122096.jpg",
        "book_title": "Manacled",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 925,
        "author": "SenLinYu",
        "genres": [
            "Fantasy",
            "Romance",
            "Fiction",
            "Dystopia",
            "Adult"
        ],
        "num_ratings": 87234,
        "num_reviews": 16428,
        "average_rating": 4.65,
        "year": 2018,
        "month": 4,
        "url_storygraph": "https://app.thestorygraph.com/books/b864c1ec-d222-44fe-9862-4e6aa6b35789",
        "average_rating_storygraph": null,
        "num_ratings_storygraph": null,
        "average_rating_true": 4.65,
        "top_num_ratings": "Top 10k",
        "top_num_reviews": "Top 10k",
        "description_storygraph": null
    },
    {
        "book_id_title": "53239311-the-happiest-man-on-earth",
        "book_id": 53239311,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1595373758i/53239311.jpg",
        "book_title": "The Happiest Man on Earth",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 208,
        "author": "Eddie Jaku",
        "genres": [
            "Nonfiction",
            "Biography"
        ],
        "num_ratings": 83882,
        "num_reviews": 7588,
        "average_rating": 4.62,
        "year": 2020,
        "month": 7,
        "url_storygraph": "https://app.thestorygraph.com/books/a1651886-6810-41e1-b52c-019507827617",
        "average_rating_storygraph": 4.68,
        "num_ratings_storygraph": 3771,
        "average_rating_true": 4.65,
        "top_num_ratings": "Top 10k",
        "top_num_reviews": "Top 10k",
        "description_storygraph": "The Happiest Man on Earth by Eddie Jaku is a powerful and inspiring memoir that is a must-read for anyone seeking a hopeful and uplifting account of resilience, hope, and the human spirit, particularly those interested in the Holocaust, World War II, and memoirs of survival and triumph over adversity."
    },
    {
        "book_id_title": "7235533-the-way-of-kings",
        "book_id": 7235533,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1659905828i/7235533.jpg",
        "book_title": "The Way of Kings",
        "book_series": "The Stormlight Archive #1",
        "book_series_uri": "https://www.goodreads.com/series/49075-the-stormlight-archive",
        "num_pages": 1007,
        "author": "Brandon Sanderson",
        "genres": [
            "Fantasy",
            "Fiction",
            "Adult",
            "Adventure"
        ],
        "num_ratings": 507145,
        "num_reviews": 36911,
        "average_rating": 4.66,
        "year": 2010,
        "month": 8,
        "url_storygraph": "https://app.thestorygraph.com/books/10a10b05-04cf-4dac-ba55-04c6f68d10a7",
        "average_rating_storygraph": 4.63,
        "num_ratings_storygraph": 40390,
        "average_rating_true": 4.64,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "The Way of Kings by Brandon Sanderson is a perfect fit for readers who crave immersive, epic fantasy adventures that explore complex themes of world-building, honor, and the struggle between faith and power, with a richly detailed setting and intricate characters that will transport them to a world of stone and storms."
    },
    {
        "book_id_title": "77727.Calvin_and_Hobbes",
        "book_id": 77727,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1441227694i/77727.jpg",
        "book_title": "Calvin and Hobbes",
        "book_series": "Calvin and Hobbes #1",
        "book_series_uri": "https://www.goodreads.com/series/58116-calvin-and-hobbes",
        "num_pages": 128,
        "author": "Bill Watterson",
        "genres": [
            "Comics",
            "Humor",
            "Fiction",
            "Childrens",
            "Classics"
        ],
        "num_ratings": 147438,
        "num_reviews": 1089,
        "average_rating": 4.61,
        "year": 1987,
        "month": 1,
        "url_storygraph": "https://app.thestorygraph.com/books/f016b8e9-f60f-4df6-9bda-574875ba099e",
        "average_rating_storygraph": 4.68,
        "num_ratings_storygraph": 5264,
        "average_rating_true": 4.64,
        "top_num_ratings": "Top 10k",
        "top_num_reviews": "-",
        "description_storygraph": "Calvin and Hobbes by Bill Watterson is a delightful and imaginative tale that is a perfect fit for readers of all ages who cherish the carefree spirit of childhood, the power of imagination, and the joy of laughter, making it a must-read for anyone seeking a lighthearted and adventurous journey through the eyes of a curious and mischievous 6-year-old boy and his trusty tiger sidekick."
    },
    {
        "book_id_title": "127305853-the-women",
        "book_id": 127305853,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1689788358i/127305853.jpg",
        "book_title": "The Women",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 480,
        "author": "Kristin Hannah",
        "genres": [
            "Fiction",
            "Historical",
            "Adult"
        ],
        "num_ratings": 423771,
        "num_reviews": 51009,
        "average_rating": 4.65,
        "year": 2024,
        "month": 2,
        "url_storygraph": "https://app.thestorygraph.com/books/4cb9c964-4ddb-42c7-8cec-e527a9ebe8df",
        "average_rating_storygraph": 4.59,
        "num_ratings_storygraph": 14983,
        "average_rating_true": 4.62,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "The Women by Kristin Hannah is a good fit for readers who appreciate historical fiction, strong female protagonists, and stories that explore the complexities of war, sacrifice, and the struggles of women who served in the Vietnam War, as well as those who are drawn to emotional and enlightening tales of love, loss, and self-discovery."
    },
    {
        "book_id_title": "136251.Harry_Potter_and_the_Deathly_Hallows",
        "book_id": 136251,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1663805647i/136251.jpg",
        "book_title": "Harry Potter and the Deathly Hallows",
        "book_series": "Harry Potter #7",
        "book_series_uri": "https://www.goodreads.com/series/45175-harry-potter",
        "num_pages": 759,
        "author": "J.K. Rowling",
        "genres": [
            "Fantasy",
            "Young Adult",
            "Fiction",
            "Childrens",
            "Adventure",
            "Classics"
        ],
        "num_ratings": 3745910,
        "num_reviews": 86737,
        "average_rating": 4.62,
        "year": 2007,
        "month": 7,
        "url_storygraph": "https://app.thestorygraph.com/books/6b276e97-e346-4b96-9d66-0b7f8aa4a02f",
        "average_rating_storygraph": 4.62,
        "num_ratings_storygraph": 231920,
        "average_rating_true": 4.62,
        "top_num_ratings": "Top 100",
        "top_num_reviews": "Top 100",
        "description_storygraph": null
    },
    {
        "book_id_title": "17927395-a-court-of-mist-and-fury",
        "book_id": 17927395,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1546406996i/17927395.jpg",
        "book_title": "A Court of Mist and Fury",
        "book_series": "A Court of Thorns and Roses #2",
        "book_series_uri": "https://www.goodreads.com/series/104014-a-court-of-thorns-and-roses",
        "num_pages": 626,
        "author": "Sarah J. Maas",
        "genres": [
            "Fantasy",
            "Romance",
            "Young Adult",
            "Fiction"
        ],
        "num_ratings": 2032836,
        "num_reviews": 174848,
        "average_rating": 4.65,
        "year": 2016,
        "month": 5,
        "url_storygraph": "https://app.thestorygraph.com/books/04729ac0-cdb0-45bb-a2cc-a6f9ef413bc2",
        "average_rating_storygraph": 4.56,
        "num_ratings_storygraph": 230389,
        "average_rating_true": 4.61,
        "top_num_ratings": "Top 100",
        "top_num_reviews": "Top 100",
        "description_storygraph": null
    },
    {
        "book_id_title": "22299763-crooked-kingdom",
        "book_id": 22299763,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1456172607i/22299763.jpg",
        "book_title": "Crooked Kingdom",
        "book_series": "Six of Crows  #2",
        "book_series_uri": "https://www.goodreads.com/series/131836-six-of-crows",
        "num_pages": 561,
        "author": "Leigh Bardugo",
        "genres": [
            "Fantasy",
            "Young Adult",
            "Fiction",
            "Romance",
            "Adventure"
        ],
        "num_ratings": 652401,
        "num_reviews": 73018,
        "average_rating": 4.59,
        "year": 2016,
        "month": 9,
        "url_storygraph": "https://app.thestorygraph.com/books/7d5abf73-0d77-4c32-866f-b76feec0b3af",
        "average_rating_storygraph": 4.62,
        "num_ratings_storygraph": 135548,
        "average_rating_true": 4.61,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "Crooked Kingdom by Leigh Bardugo is a thrilling and emotional ride that is a perfect fit for readers who crave complex characters, high-stakes adventure, and a deep exploration of found family and enemies-to-lovers relationships, with a dash of dark magic and heists that will keep them on the edge of their seats."
    },
    {
        "book_id_title": "34002132-oathbringer",
        "book_id": 34002132,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1654573897i/34002132.jpg",
        "book_title": "Oathbringer",
        "book_series": "The Stormlight Archive #3",
        "book_series_uri": "https://www.goodreads.com/series/49075-the-stormlight-archive",
        "num_pages": 1243,
        "author": "Brandon Sanderson",
        "genres": [
            "Fantasy",
            "Fiction",
            "Adult",
            "Adventure"
        ],
        "num_ratings": 252840,
        "num_reviews": 15785,
        "average_rating": 4.62,
        "year": 2017,
        "month": 11,
        "url_storygraph": "https://app.thestorygraph.com/books/046c4ef7-9c13-45fa-bd9e-857af78360e6",
        "average_rating_storygraph": 4.58,
        "num_ratings_storygraph": 24114,
        "average_rating_true": 4.6,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 10k",
        "description_storygraph": "Oathbringer by Brandon Sanderson is a thrilling and emotionally resonant epic fantasy novel that is a perfect fit for readers who crave complex world-building, morally nuanced characters, and a richly detailed story that explores the themes of war, redemption, and the struggle for unity in the face of catastrophic destruction."
    },
    {
        "book_id_title": "49021976-rhythm-of-war",
        "book_id": 49021976,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1599911216i/49021976.jpg",
        "book_title": "Rhythm of War",
        "book_series": "The Stormlight Archive #4",
        "book_series_uri": "https://www.goodreads.com/series/49075-the-stormlight-archive",
        "num_pages": 1219,
        "author": "Brandon Sanderson",
        "genres": [
            "Fantasy",
            "Fiction",
            "Adult",
            "Adventure"
        ],
        "num_ratings": 151559,
        "num_reviews": 11386,
        "average_rating": 4.62,
        "year": 2020,
        "month": 11,
        "url_storygraph": "https://app.thestorygraph.com/books/85b30674-e613-41cb-ad4b-926db63491bf",
        "average_rating_storygraph": 4.57,
        "num_ratings_storygraph": 16559,
        "average_rating_true": 4.6,
        "top_num_ratings": "Top 10k",
        "top_num_reviews": "Top 10k",
        "description_storygraph": "Rhythm of War by Brandon Sanderson is a thrilling and emotionally resonant epic fantasy novel that is a perfect fit for readers who crave complex world-building, morally nuanced characters, and a rich exploration of themes such as mental health, magic, and the struggle between light and darkness, all set against a backdrop of high-stakes war and adventure."
    },
    {
        "book_id_title": "56060300-heartstopper",
        "book_id": 56060300,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1640745736i/56060300.jpg",
        "book_title": "Heartstopper: Volume Four",
        "book_series": "Heartstopper #4",
        "book_series_uri": "https://www.goodreads.com/series/236821-heartstopper",
        "num_pages": 384,
        "author": "Alice Oseman",
        "genres": [
            "Romance",
            "Young Adult",
            "Contemporary",
            "Comics",
            "Fiction"
        ],
        "num_ratings": 386404,
        "num_reviews": 36719,
        "average_rating": 4.6,
        "year": 2021,
        "month": 5,
        "url_storygraph": "https://app.thestorygraph.com/books/2f975093-d39c-4c8d-b64b-a5e78b18329d",
        "average_rating_storygraph": 4.6,
        "num_ratings_storygraph": 76478,
        "average_rating_true": 4.6,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "Heartstopper Vol 4. Ediz. da collezione by Alice Oseman is a perfect fit for readers who appreciate heartfelt, relatable stories about self-discovery, love, and mental health, particularly those in the LGBTQ+ community and young adults who are looking for a hopeful and lighthearted tale of first love and coming-of-age."
    },
    {
        "book_id_title": "5.Harry_Potter_and_the_Prisoner_of_Azkaban",
        "book_id": 5,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1630547330i/5.jpg",
        "book_title": "Harry Potter and the Prisoner of Azkaban",
        "book_series": "Harry Potter #3",
        "book_series_uri": "https://www.goodreads.com/series/45175-harry-potter",
        "num_pages": 435,
        "author": "J.K. Rowling",
        "genres": [
            "Fantasy",
            "Fiction",
            "Young Adult",
            "Childrens",
            "Adventure",
            "Classics"
        ],
        "num_ratings": 4243171,
        "num_reviews": 85599,
        "average_rating": 4.58,
        "year": 1999,
        "month": 7,
        "url_storygraph": "https://app.thestorygraph.com/books/d93d6f72-8d62-4294-b087-91632fac143a",
        "average_rating_storygraph": 4.6,
        "num_ratings_storygraph": 261774,
        "average_rating_true": 4.59,
        "top_num_ratings": "Top 10",
        "top_num_reviews": "Top 100",
        "description_storygraph": null
    },
    {
        "book_id_title": "58687126-finding-me",
        "book_id": 58687126,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1650842995i/58687126.jpg",
        "book_title": "Finding Me",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 304,
        "author": "Viola Davis",
        "genres": [
            "Nonfiction",
            "Biography",
            "Adult"
        ],
        "num_ratings": 123433,
        "num_reviews": 16123,
        "average_rating": 4.56,
        "year": 2022,
        "month": 4,
        "url_storygraph": "https://app.thestorygraph.com/books/a77dc6e1-8809-480f-b9ab-0a5ca707104d",
        "average_rating_storygraph": 4.62,
        "num_ratings_storygraph": 12771,
        "average_rating_true": 4.59,
        "top_num_ratings": "Top 10k",
        "top_num_reviews": "Top 10k",
        "description_storygraph": "Finding Me by Viola Davis is a perfect fit for readers seeking a deeply personal and inspiring memoir that explores themes of resilience, self-discovery, and healing, particularly those who have experienced poverty, struggle, or feelings of disconnection and are looking for a powerful story of transformation and empowerment."
    },
    {
        "book_id_title": "21853621-the-nightingale",
        "book_id": 21853621,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1681839850i/21853621.jpg",
        "book_title": "The Nightingale",
        "book_series": "",
        "book_series_uri": "",
        "num_pages": 440,
        "author": "Kristin Hannah",
        "genres": [
            "Fiction",
            "Historical",
            "Romance",
            "Adult"
        ],
        "num_ratings": 1411614,
        "num_reviews": 113613,
        "average_rating": 4.62,
        "year": 2015,
        "month": 2,
        "url_storygraph": "https://app.thestorygraph.com/books/063c58de-2e9c-4117-a13d-8d97988735c2",
        "average_rating_storygraph": 4.54,
        "num_ratings_storygraph": 73139,
        "average_rating_true": 4.58,
        "top_num_ratings": "Top 100",
        "top_num_reviews": "Top 100",
        "description_storygraph": "The Nightingale by Kristin Hannah is a heart-wrenching and inspiring novel that is a perfect fit for readers who appreciate historical fiction, strong female protagonists, and stories of resilience, courage, and the unbreakable bonds of sisterhood, as it masterfully weaves together the threads of love, loss, and survival during World War II."
    },
    {
        "book_id_title": "28260587-empire-of-storms",
        "book_id": 28260587,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1463107108i/28260587.jpg",
        "book_title": "Empire of Storms",
        "book_series": "Throne of Glass #5",
        "book_series_uri": "https://www.goodreads.com/series/51288-throne-of-glass",
        "num_pages": 689,
        "author": "Sarah J. Maas",
        "genres": [
            "Fantasy",
            "Young Adult",
            "Romance",
            "Fiction"
        ],
        "num_ratings": 710555,
        "num_reviews": 58659,
        "average_rating": 4.62,
        "year": 2016,
        "month": 9,
        "url_storygraph": "https://app.thestorygraph.com/books/9548e55c-92a6-4e72-b5df-be4df30708e6",
        "average_rating_storygraph": 4.54,
        "num_ratings_storygraph": 96435,
        "average_rating_true": 4.58,
        "top_num_ratings": "Top 1k",
        "top_num_reviews": "Top 1k",
        "description_storygraph": "Empire of Storms by Sarah J. Maas is a thrilling and emotionally charged adventure that is a perfect fit for readers who crave epic fantasy, complex characters, and found family dynamics, and are eager to embark on a journey of self-discovery, loyalty, and sacrifice with Aelin Galathynius as she battles to save her world from darkness."
    }
]


export default initialBooks;