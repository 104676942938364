import React from 'react';
import { Container, Typography, Box, Link, Paper, ThemeProvider } from '@mui/material';
import getTheme from './theme'; // Importing getTheme
import './Promote.css';
import { Helmet } from 'react-helmet-async';


const SectionPaper = (props) => {
    return (
      <Paper style={{
        margin: '1rem 0',
        padding: '1rem',
        backgroundColor: props.theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
        border: `1px solid ${props.theme.palette.divider}`,
        '&:not(:last-child)': {
          borderBottom: 0,
        }
      }}>
        {props.children}
      </Paper>
    );
  };

const Promote = ({ theme }) => {
  const currentTheme = getTheme(theme === 'dark' ? 'dark' : 'light'); // Using the getTheme function

  return (
    <div>
    <Helmet>
    <title>BookFilter | Promote</title>
    <meta name="description" content="Want to increase the visibility of your book? Drop us an email for more information." />
    </Helmet>
    <ThemeProvider theme={currentTheme}> {/* Applying the ThemeProvider */}
      <Container style={{ marginTop: '3rem' }}>
        <Box>
          <Typography variant="h1"
                      style={{ fontSize: '2.5rem'}} 
                      className="faq-title">
                Promote
            </Typography>
          <Box className="p-3">
            <SectionPaper theme={currentTheme}>
              <Typography variant="h5">The Service</Typography>
              <Typography>
                Providing book visibility for authors through promotion on the main page.
                Promoted books will be easily distinguishable from non-promoted books to maintain a good user experience.
              </Typography>
            </SectionPaper>
            <SectionPaper theme={currentTheme}>
              <Typography variant="h5">Manuscript Submission Guidelines</Typography>
              <Typography>
                Submit a digital copy of your completed manuscript to{' '}
                <Link href="mailto:promote@book-filter.com">promote@book-filter.com</Link>.
                Include other relevant details such as the book description, genres and Goodreads link (if it exists).
                Please also include a link to the Amazon page for the book or a personal page from which the book is being sold and distributed.
              </Typography>
            </SectionPaper>
            <SectionPaper theme={currentTheme}>
              <Typography variant="h5">Preliminary Evaluation</Typography>
              <Typography>
                Promotion fees will be disclosed via email after the initial book screening has been completed.
                Books may be rejected if they fail to meet compliance with certain legal and ethical guidelines.
              </Typography>
            </SectionPaper>
            <SectionPaper theme={currentTheme}>
              <Typography variant="h5">Further Inquiries</Typography>
              <Typography>
                For further information, questions or promotion fee estimates contact{' '}
                <Link href="mailto:support@book-filter.com">support@book-filter.com</Link>.
              </Typography>
            </SectionPaper>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </div>
  );
};

export default Promote;
