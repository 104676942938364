// ShareButton.js
import React from 'react';
import './ShareButton.css';


const ShareButton = () => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'BookFilter',
        url: 'https://book-filter.com',
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    }
  };

  return (
    <button className="share-button" onClick={handleShare}>Share</button>
  );
};

export default ShareButton;
