const promotedBooks = [
    {
        "book_id_title": "54883240-the-seventh-year-trials",
        "book_id": 54883240,
        "cover_image_uri": "https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1597773831i/54883240.jpg",
        "book_title": "The Seventh Year Trials",
        "author": "Alexis D. Johnson",
        "genres": [
            "Fantasy",
            "Young Adult"
        ],
        "num_ratings": 48,
        "num_reviews": 29,
        "num_pages": 588,
        "average_rating": 4.75,
        "url_storygraph": "https://app.thestorygraph.com/books/a2b1fae2-5d16-49b9-bc63-4eb771358967",
        "average_rating_storygraph": 4.8,
        "num_ratings_storygraph": 5,
        "average_rating_true": 4.78,
        "top_num_ratings": "-",
        "top_num_reviews": "-",
        "year": 2020,
        "month": 8,

        "description": "Desperate to save her orphaned dragon, Fifteen-year-old Mirren enters the Seventh Year Trials – a treacherous dragon riding competition for the nation’s wealthiest youth. But Mirren’s far from a member of the elite, and one vindictive ex-noble is determined to win at any cost in a game all his own.",
        "amazon_link": "https://www.amazon.com/dp/1735135607", 
        "show": 0
    }
]

export default promotedBooks;