// Main.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BookList from './BookList';
import './Loader.css';
import RatingSlider from './RatingSlider.js'
import MultipleSelectChip from './MultipleSelector.js'
import SingleSelector from './singleSelector.js'
import RadioButtonsGroup from './RadioButtons.js'
import TextField from '@mui/material/TextField'; // Import TextField
import ShareButton from './ShareButton';
import { ThemeProvider } from '@mui/material/styles';
import getTheme from './theme';
import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import { Helmet } from 'react-helmet-async';


function Main({
  theme,
  isMobile,
  presetMinRating,
  presetMaxRating,
  presetMinRatingNo,
  presetMaxRatingNo,
  presetMinYear,
  presetMaxYear,
  presetSort,
  presetInclude,
  presetExclude,
  presetInitialBooks,
  title_meta,
  description_meta})

  {
  const [books, setBooks] = useState(presetInitialBooks);
  const [promotedBooks, setPromotedBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [minRating, setMinRating] = useState(presetMinRating);
  const [maxRating, setMaxRating] = useState(presetMaxRating);
  const [minRatingNo, setMinRatingNo] = useState(presetMinRatingNo);
  const [maxRatingNo, setMaxRatingNo] = useState(presetMaxRatingNo);
  const [minYear, setMinYear] = useState(presetMinYear);
  const [maxYear, setMaxYear] = useState(presetMaxYear);
  const [genres, setGenres] = useState(presetInclude);
  const [genresExclude, setGenresExclude] = useState(presetExclude);
  const [sort, setSort] = React.useState(presetSort);
  const [type, setType] = React.useState('');
  const [page, setPage] = useState(1); // Add a new state for current page
  const [hasMore, setHasMore] = useState(true); // State to track if more data is available
  const [triggerFetch, setTriggerFetch] = useState(false);


  const searchTermRef = useRef(searchTerm);
  const minRatingRef = useRef(minRating);
  const maxRatingRef = useRef(maxRating);
  const minRatingNoRef = useRef(minRatingNo);
  const maxRatingNoRef = useRef(maxRatingNo);
  const minYearRef = useRef(minYear);
  const maxYearRef = useRef(maxYear);
  const genresRef = useRef(genres);
  const genresExcludeRef = useRef(genresExclude);
  const sortRef = useRef(sort);
  const typeRef = useRef(type);


// Update refs whenever minRating or maxRating changes
  useEffect(() => {
  searchTermRef.current = searchTerm;
  minRatingRef.current = minRating;
  maxRatingRef.current = maxRating;
  minRatingNoRef.current = minRatingNo;
  maxRatingNoRef.current = maxRatingNo;
  minYearRef.current = minYear;
  maxYearRef.current = maxYear;
  genresRef.current = genres;
  genresExcludeRef.current = genresExclude;
  sortRef.current = sort;
  typeRef.current = type;


}, [searchTerm, minRating, maxRating, minRatingNo, maxRatingNo, minYear, maxYear, genres, genresExclude, sort, type]);


  const fetchBooks = useCallback(async (append = false) => {
    setIsLoading(true);
    
    // Convert the array to a JSON string
    const genreString = JSON.stringify(genresRef.current);
    const genreXString = JSON.stringify(genresExcludeRef.current);

    const response = await fetch(`/api/books?searchTerm=${searchTermRef.current}&minRating=${minRatingRef.current}&maxRating=${maxRatingRef.current}&minRatingNo=${minRatingNoRef.current}&maxRatingNo=${maxRatingNoRef.current}&minYear=${minYearRef.current}&maxYear=${maxYearRef.current}&genreString=${genreString}&genreXString=${genreXString}&sort=${sortRef.current}&type=${typeRef.current}&page=${page}&booksPerPage=20`);
    const data = await response.json();
    console.log(data); // Print the JSON data


    // const response_promo = await fetch(`/api/promo`);
    // const promoted_books = await response_promo.json();
    // setPromotedBooks(promoted_books)
    setPromotedBooks([])

    if (append) {
      setBooks(prevBooks => [...prevBooks, ...data]); // Append new books to existing list
    } else {
      setBooks(data);
    }
    setHasMore(data.length > 0); // If no data returned, set hasMore to false
    setIsLoading(false);
  }, [page]);
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleApplyClick = () => {
    setBooks([]);
    setPage(1)
    setHasMore(true); // Reset hasMore on new search/filter
    setTriggerFetch(prev => !prev); // Toggle the triggerFetch state
  };
  
  useEffect(() => {
    if (page > 20) {
      setHasMore(false)
    }
    else if (page > 1 && hasMore) {
      fetchBooks(true);
    }
    else if (page === 1) {
      fetchBooks()
    }
  }, [page, fetchBooks, hasMore, triggerFetch]);

  const currentTheme = getTheme(theme === 'dark' ? 'dark' : 'light');

  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.keyCode === 13) { // 13 is the Enter key
        handleApplyClick();
      }
    };
  
    document.addEventListener('keydown', handleEnterKey);
  
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, []);
  

  const device_type = isMobile ? 'mobile' : 'desktop'
  const device_width = isMobile ? '100%' : '55%'

  return (
    <>
      <Helmet>
      <title>{title_meta} </title>
      <meta
      name="description"
      content={description_meta}/>
      </Helmet>
    <Container fluid className={device_type} style={{ width: device_width, maxWidth: '1000px', margin: '0 auto' }}>
    <Row>
    <Col md={12}>
    <div style={{ marginBottom: '20px' }}>
      <span className="buttons-title">Try one:</span>
      <a className="buttons" href="/most-popular-books-of-all-time">Popular all time</a>
      <a className="buttons" href="/most-underrated-books">Most underrated</a>
      <a className="buttons" href="/top-romance-books">Best Romance</a>
      <a className="buttons" href="/top-fantasy-books">Best Fantasy</a>
      <a className="buttons" href="/top-books-this-year">Popular this year</a>
      </div>
    </Col>
    </Row>
    <Row>
        <Col md={12}>
      <div className="input-group mb-3">
      <Row>
        <Col md={12}>
        {isMobile ? <ShareButton /> : null}
      <div className="slider-container">
    <label className="slider-label">Average Rating:</label>
      <RatingSlider
        setMinRating={setMinRating}
        setMaxRating={setMaxRating}
        min={0}
        max={5}
        starting_point={presetMinRating}
        end_point={presetMaxRating}
        step1={0.03}
        step2={0.01}
        step3={0.01}
        decimals={2}
        separator={''}
        tenth = {1}
        halfway = {4}
      />
      </div>
      <div className="space-slider"></div>
      <div className="slider-container">
    <label className="slider-label">No. of Ratings:</label>
      <RatingSlider
        setMinRating={setMinRatingNo}
        setMaxRating={setMaxRatingNo}
        min={0}
        max={11000000}
        starting_point={presetMinRatingNo}
        end_point={presetMaxRatingNo}
        step1={1000}
        step2={5000}
        step3={10000}
        decimals={0}
        separator={','}
        tenth = {50000}
        halfway = {500000}
      />
      </div>
      <div className="space-slider"></div>
      <div className="slider-container">
    <label className="slider-label">Year Published:</label>
      <RatingSlider
        setMinRating={setMinYear}
        setMaxRating={setMaxYear}
        min={-2000}
        max={2024}
        starting_point={presetMinYear}
        end_point={presetMaxYear}
        step1={30}
        step2={1}
        step3={1}
        decimals={0}
        separator={''}
        tenth = {0}
        halfway = {1960}
      />
      </div>
      </Col>
      </Row>
      <div className="space"></div>
      <ThemeProvider theme={currentTheme}>
      <MultipleSelectChip
        genres={genres}
        setGenres={setGenres}
        label={"Genres"}
       />
      <div className="space"></div>
      <MultipleSelectChip
        genres={genresExclude}
        setGenres={setGenresExclude}
        label={"Exclude"}
        />
      <div className="double-container">
        <TextField
        label="Search by title, author or series"
        value={searchTerm}
        onChange={handleSearchChange}
        variant="outlined"
        sx={{ m: 0}} // Add some margin
        />
        <SingleSelector sort={sort} setSort={setSort} />
      </div>
      <RadioButtonsGroup type={type} setType={setType}/>
      
        <div>
        <Button variant="contained" onClick={handleApplyClick}>Apply</Button>
        <div style={{ fontSize: "12px", fontFamily: "sans-serif", marginTop: "5px" }}>
        (Too complicated? Try out a more <a href="/top-10/All-time/Fiction">simple filter</a>)
        </div>
        </div>
        </ThemeProvider>
      </div>
      </Col>
      </Row>
      <Row>
        <Col md={12}>
        {
          isLoading || books.length > 0
            ? <BookList isLoading={isLoading} books={books} promotedBooks={promotedBooks} setPage={setPage} isMobile={isMobile} theme={theme} />
            : minRatingNoRef.current > 0
              ? <p className="d-flex justify-content-center align-items-center">Please try lowering the number of ratings.</p>
              : <p className="d-flex justify-content-center align-items-center">Sorry, no search results found.</p>
        }     
      </Col>
      </Row>
      <Row>
        <Col md={12}>
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80px' }}>
          <div className="loader">
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
      )}
            </Col>
      </Row>

      {!isLoading && hasMore && (
        <div
          id="load-more-anchor"
          style={{ height: '20px', margin: '10px 0' }}
        ></div>
      )}
    </Container>
    </>
  );
}

export default Main;
