import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SingleSelector({ sort, setSort }) {

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  return (
    <div>
      {/* Adjust margin to bring closer */}
      <FormHelperText sx={{ mb: 0 }}>Sort by</FormHelperText>
      
      {/* Set fixed size and adjust margin */}
      <FormControl sx={{ m: 0, minWidth: 120, width: 'auto' }}>
        <Select
          value={sort}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ width: 200 }} // Set fixed width and height
        >
          <MenuItem value={"average_rating_true"}>Average Rating</MenuItem>
          <MenuItem value={"num_ratings"}>Popularity</MenuItem>
          <MenuItem value={"year DESC, month"}>Most Recent</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
