import { createTheme } from '@mui/material/styles';

// Function to create a theme based on the mode
const getTheme = (mode) => createTheme({
  palette: {
    mode, // 'light' or 'dark'
    ...(mode === 'dark' ? {
      // Define dark mode specific palette
      background: {
        default: '#121212',
        paper: '#1e1e1e',
      },
      text: {
        primary: '#fff',
        secondary: '#aaa',
      },
    } : {
      // Define light mode specific palette
      background: {
        default: '#fff',
        paper: '#f5f5f5',
      },
      text: {
        primary: '#000',
        secondary: '#555',
      },
    }),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '@media (max-width:375px)': {
            maxWidth: '80vw',
          }
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '@media (max-width:375px)': {
            maxWidth: '80vw',
          }
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: '5px',
          paddingBottom: '5px',
          backgroundColor: 'transparent', // Set background to transparent
          color: mode === 'dark' ? '#fff' : '#000', // Different text color for dark and light mode
        },
      },
    },
    MuiTextField: {
      // Adding style overrides for TextField
      styleOverrides: {
        root: {
          // Target the input element inside the TextField
          '& .MuiInputBase-input': {
            paddingTop: '5px', // Adjust this value as needed
            paddingBottom: '5px',
          },
          width: '20rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Add your custom styles here
          top: '-.6rem', // Adjust this to move the label up or down
          // You can also adjust other properties like font size, if needed
        },
        shrink: {
          // Styles when the label is shrunk (i.e., when the TextField is focused or filled)
          top: '0px', // Adjust this as needed
          // Other adjustments for the shrunk state
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: { // Target the 'contained' variant
          backgroundColor: 'var(--text2)', // Custom color
          '&:hover': {
            backgroundColor: 'var(--text2)', // Custom hover color
          },
        },
      },
    },
  },
});

export default getTheme;
