import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { inject } from '@vercel/analytics'; // Import the inject function
import Main from './Main';
import SimpleFilter from './SimpleFilter.js'
import About from './About';
import Promote from './Promote'
import NavBar from './navigationBar.js'
import { Container, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import './App.css'; // Ensure you have this for additional styling
import {useMediaQuery } from '@mui/material';
import initialBooks from './initialBooksData'; // Adjust the path as per your file structure





function App() {

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light'); // New state for theme


  useEffect(() => {

    inject(); // This will add the Vercel Analytics tracking script

    // Determine the initial theme preference
    const storedTheme = localStorage.getItem('theme');
    const systemPreference = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    const initialTheme = storedTheme || systemPreference;

    // Set the initial theme
    document.body.className = initialTheme; // Synchronize body class with initial theme
    setTheme(initialTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    document.body.className = newTheme; // Update the body class immediately
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
};

function useIsMobile() {
  const isMobile = useMediaQuery('(max-width:780px)');
  return isMobile
}

const isMobile = useIsMobile()

const device_type = isMobile ? 'mobile-theme-toggle btn' : 'desktop-theme-toggle btn'

function SimpleFilterWrapper() {
  let { year, genre } = useParams();
  
  return <SimpleFilter theme={theme}
  isMobile={isMobile}
  presetMinRating={0}
  presetMaxRating={5}
  presetMinRatingNo={0}
  presetMaxRatingNo={11000000}
  presetMinYear={year}
  presetMaxYear={year}
  presetSort={'num_ratings'}
  presetInclude={[`${genre}`]}
  presetExclude={[]}
  presetInitialBooks={[]}
  />;
}


  return (
    <>
        <Container fluid className={`no-padding ${theme}`}>
    <Router>
        <Col md={12}>
    <NavBar></NavBar>
    <div className="d-flex justify-content-between align-items-center mb-4">
        <button onClick={toggleTheme} className={device_type}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#6c757d" className="bi bi-circle-half" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
        </svg>
        </button>
    </div>
    </Col>
      <Container fluid>
      <Row>
        <Col md={12}>
      <Routes>
        <Route path="/" element={<Main theme={theme}
                                       isMobile={isMobile}
                                       presetMinRating={0}
                                       presetMaxRating={5}
                                       presetMinRatingNo={50000}
                                       presetMaxRatingNo={11000000}
                                       presetMinYear={0}
                                       presetMaxYear={2024}
                                       presetSort={'average_rating_true'}
                                       presetInclude={[]}
                                       presetExclude={[]}
                                       presetInitialBooks={initialBooks}
                                       title_meta={"BookFilter | Sort books by ratings, genre and more."}
                                       description_meta={"Book Filter uses advanced filters to help you find your next great read. Sort and filter books by ratings, genre, most recent and more!"}
                                       />} />
        <Route path="top-10/:year/:genre" element={<SimpleFilterWrapper />} />
        <Route path="/top-books-this-year" element={<Main theme={theme}
                                       isMobile={isMobile}
                                       presetMinRating={0}
                                       presetMaxRating={5}
                                       presetMinRatingNo={0}
                                       presetMaxRatingNo={11000000}
                                       presetMinYear={2024}
                                       presetMaxYear={2024}
                                       presetSort={'num_ratings'}
                                       presetInclude={[]}
                                       presetExclude={['Comics', 'Religion']}
                                       presetInitialBooks={[]}
                                       title_meta={"BookFilter | The Top Books This Year"}
                                       description_meta={"Stay current with the literary world on Book Filter. From trending bestsellers to critically acclaimed novels, discover the hottest books released in the current year. Filter by genre, reader reviews, and ratings to quickly find the top reads that everyone is talking about. Start reading the best of the year today!"}
                                       />} />
        <Route path="/most-underrated-books" element={<Main theme={theme}
                                       isMobile={isMobile}
                                       presetMinRating={4}
                                       presetMaxRating={5}
                                       presetMinRatingNo={5000}
                                       presetMaxRatingNo={15000}
                                       presetMinYear={2000}
                                       presetMaxYear={2024}
                                       presetSort={'average_rating_true'}
                                       presetInclude={[]}
                                       presetExclude={['Comics', 'Religion', 'Christian']}
                                       presetInitialBooks={[]}
                                       title_meta={"BookFilter | Most Underrated"}
                                       description_meta={"Uncover overlooked masterpieces with Book Filter. Dive into our unique collection of lesser-known books that deserve more attention. Perfectly categorized by genre, rating, and publication date, find your hidden treasure today!"}

                                       />} />
        <Route path="/top-romance-books" element={<Main theme={theme}
                                       isMobile={isMobile}
                                       presetMinRating={4}
                                       presetMaxRating={5}
                                       presetMinRatingNo={1000000}
                                       presetMaxRatingNo={11000000}
                                       presetMinYear={1950}
                                       presetMaxYear={2024}
                                       presetSort={'average_rating_true'}
                                       presetInclude={['Romance']}
                                       presetExclude={['Fantasy']}
                                       presetInitialBooks={[]}
                                       title_meta={"BookFilter | The Best Romance Books"}
                                       description_meta={"Fall in love with our collection at Book Filter. Browse the best in romance, from timeless classics to contemporary favorites, all handpicked for their heartwarming tales. Sort by popularity, reader ratings, or publication date, and discover your next favorite love story today!"}
                                       />} />
        <Route path="/top-fantasy-books" element={<Main theme={theme}
                                       isMobile={isMobile}
                                       presetMinRating={4}
                                       presetMaxRating={5}
                                       presetMinRatingNo={1000000}
                                       presetMaxRatingNo={11000000}
                                       presetMinYear={1950}
                                       presetMaxYear={2024}
                                       presetSort={'average_rating_true'}
                                       presetInclude={['Fantasy']}
                                       presetExclude={['Romance']}
                                       presetInitialBooks={[]}
                                       title_meta={"BookFilter | The Best Fantasy Books"}
                                       description_meta={"Embark on an epic adventure with Book Filter. Explore our expertly curated list of top fantasy novels, featuring everything from magical realms to legendary heroes. Whether you’re seeking new releases or timeless classics, filter by ratings or publication date to discover your next fantastical journey!"}
                                       />} />
        <Route path="/most-popular-books-of-all-time" element={<Main theme={theme}
                                       isMobile={isMobile}
                                       presetMinRating={4}
                                       presetMaxRating={5}
                                       presetMinRatingNo={0}
                                       presetMaxRatingNo={11000000}
                                       presetMinYear={-2000}
                                       presetMaxYear={2024}
                                       presetSort={'num_ratings'}
                                       presetInclude={[]}
                                       presetExclude={['Comics', 'Religion']}
                                       presetInitialBooks={initialBooks}
                                       title_meta={"BookFilter | The Most Popular Books of All Time"}
                                       description_meta={"Explore the classics that have captivated readers for generations on Book Filter. Discover our extensive collection of best-selling books, from groundbreaking novels to transformative non-fiction. Use our advanced filters to sort by genre, ratings, or historical popularity and find your next must-read masterpiece!"}
                                       />} />
        <Route path="/about" element={<About theme={theme}/>} />
        <Route path="/promote" element={<Promote theme={theme}/>} />
      </Routes>
      </Col>
      </Row>
      </Container>
    </Router>
    </Container>
     </>
  );
}

export default App;

