import "./RatingBar.css"

const RatingBar = ({ source, rating, numRatings, leftpadding, maxRating = 5 }) => {
    const filledPercentage = ((rating-1) / (maxRating-1)) * 100;

    function formatNumber(num) {
        if (num >= 1000000) {
          return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
          return (num / 1000).toFixed(0) + 'k';
        } else {
          return num.toString();
        }
      }
  
    return (
      <div className="rating-bar-container">
        <div className="rating-tooltip">  {numRatings != null ? `${formatNumber(numRatings)} ratings` : '-'}</div>
        <div className="rating-bar-outer">
          <div className="rating-bar-inner" style={{ width: `${filledPercentage}%` }}>
            <span className="rating-bar-source">{source}</span>
            <span className="rating-bar-value" style={{paddingLeft: `${leftpadding}px`}}>{rating}</span>
          </div>
        </div>
      </div>
    );
  };
  
export default RatingBar;