import React, { useEffect, useRef } from 'react';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import './RatingSlider.css';
import wNumb from 'wnumb';


const RatingSlider = ({ setMinRating, setMaxRating, min, max, step1, step2, step3, decimals, separator, starting_point, end_point, tenth, halfway }) => {
  const sliderRef = useRef();

  useEffect(() => {
    const slider = sliderRef.current; // Assign the current ref to a variable

    const formatValue = (value) => {
      if (value >= 1e6) {
        return wNumb({
          decimals: 1,
          postfix: ' M',
        }).to(value / 1e6);
      } else if (value < 0) {
        return wNumb({
          decimals: 0,
          postfix: ' BC',
        }).to(value *-1);
      } else {
        return wNumb({
          decimals: decimals,
          thousand: separator
        }).to(value);
      }
    };

    if (slider) {
      noUiSlider.create(slider, {
        start: [starting_point, end_point],
        connect: true,
        step: step1,
        range: {
          'min': min,
          '10%': [tenth, step2],
          '50%': [halfway, step3],
          'max': max
        },
        tooltips: [true, true]
      });

      slider.noUiSlider.on('update', function (values, handle) {
        let formattedValues = values.map(v => formatValue(parseFloat(v)));
        let tooltips = slider.getElementsByClassName('noUi-tooltip');

        for (let i = 0; i < tooltips.length; i++) {
          tooltips[i].innerHTML = formattedValues[i];
        }

        if (tooltips.length > 1) {
            // Use getBoundingClientRect for more accurate positioning
            let rect1 = tooltips[0].getBoundingClientRect();
            let rect2 = tooltips[1].getBoundingClientRect();
    
            let overlap = !(rect1.right < rect2.left || rect1.left > rect2.right);
    
            if (overlap) {
                tooltips[0].style.transform = 'translateY(150%) translateX(-100%)';
                tooltips[1].style.display = '';
            } else {
                tooltips[0].style.display = '';
                tooltips[1].style.display = '';
            }
        }
    
        // Original update event logic
        setMinRating(parseFloat(values[0]));
        setMaxRating(parseFloat(values[1]));
    });
    

    }

    // Use the variable in the cleanup function
    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };
  }, [setMinRating, setMaxRating, min, max, step1, step2, step3, decimals, separator, starting_point, end_point, tenth, halfway]);

  return <div id="slider-round" ref={sliderRef}></div>; // Use id for styling
};

export default RatingSlider;
